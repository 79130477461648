import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="PROSITOS" />

    <div id="wrapper">
      <h1>Prositos</h1>
      <div style={{ maxWidth: 600, margin: 'auto' }}>
        <p>Prositos is a web-based Flight Information Display System developed for EPSYS.</p>
        <h2>Features</h2>
        <ul>
          <li>Scalable</li>
          <li>Portable</li>
          <li>Multilingual</li>
          <li>Highly configurable</li>
          <li>Emulated flight tracking</li>
        </ul>
        <a target="_blank" rel="noopener noreferrer" href="http://prositos.no/"><img src="/images/prositos.png" alt="Screenshot" style={{ marginTop: 20, maxWidth: '100%' }} /></a>
        <a target="_blank" rel="noopener noreferrer" href="http://prositos.no/cl/"><img src="/images/prositos-cl.png" alt="Screenshot" style={{ marginTop: 20, maxWidth: '100%' }} /></a>
        <img src="/images/prositos-stats.png" alt="Screenshot" style={{ marginTop: 20, maxWidth: '100%' }} />
        <hr />
        <h2>Links</h2>
        <ul>
          <li><a target="_blank" rel="noopener noreferrer" href="http://prositos.no/">prositos.no</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="http://prositos.no/cl/">prositos.no/cl</a> (Classic)</li>
          <li><a target="_blank" rel="noopener noreferrer" href="http://m.prositos.no/">m.prositos.no</a> (Mobile)</li>
        </ul>
      </div>
    </div>
  </Layout>
);
